import React from 'react'
import { Layout, Row, Col, Image, Typography } from 'antd'
import tomato from './images/tomato.png'
import potato from './images/potato.jpg'

import 'antd/dist/antd.css';
import './App.css';

const { Header, Footer, Content } = Layout
const { Paragraph } = Typography

class App extends React.Component {
  render () {
    return (
      <Layout>
          <Header>
            <h1 style={{textAlign: 'center'}}>Welcome to the SolOmics</h1>
          </Header>
          <Content>
            <Row gutter={[18]} style={{margin: '16px 0'}}>
              <Col>
                <a href="/tomato"><Image className='ant-card-hoverable' src={tomato} preview={false} style={{height: '130px'}} alt="tomato" /></a>
              </Col>
              <Col>
                <a href="/tomato"><h3>Tomato</h3></a>
                <p>A comprehensive database adapted to the tomato multi-omics era.</p>
              </Col>
            </Row>
            <Row gutter={[18, 24]} style={{margin: '16px 0'}}>
              <Col>
                <a href="/potato"><Image className='ant-card-hoverable' src={potato} preview={false} alt="potato" /></a>
              </Col>
              <Col>
                <a href="/potato"><h3>Pan-potato Database</h3></a>
                <p>A comprehensive database adapted to the potato multi-omics era</p>
              </Col>
            </Row>
          </Content>     
          <Footer>
            <Row>
              <Paragraph style={{color: "#fff"}}>
                  Copyright © 中国农业科学院深圳农业基因组研究所. 粤公网安备 xxxx号
              </Paragraph>
            </Row>
          </Footer>
      </Layout>
    )
  }
}

export default App
